import React from "react";

const HeaderLogo = ({
    style = {
		width: '373px',
		height: '113px'
	},
    fill = '',
    // width = '347px',
    // height = '112px',
    viewBox = '0 0 373 113.6',
    className = ''
}) => {
    return (
        <div>
        <svg 
	//style={style}
	// width={width}
    // height={height}
    viewBox={viewBox}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
>
<g>
<path className="st0" d="M81.7,71.6c0.8-2,1.7-4.2,2.7-6.3c1-2.2,1.9-4.2,2.7-6.2s1.5-3.7,2.1-5.3c0.5-1.6,0.8-2.7,0.8-3.5
		c0-0.5-0.2-0.8-0.6-1.1c-0.4-0.2-0.9-0.5-1.4-0.6c-0.5-0.2-1-0.4-1.4-0.6c-0.4-0.2-0.6-0.5-0.6-0.9c0-0.1,0-0.3,0.1-0.5
		c0.1-0.2,0.1-0.4,0.2-0.6c2.1,0.2,4.3,0.2,6.7,0.2c1.2,0,2.3,0,3.2,0s1.8-0.1,2.6-0.2c0.1,0.2,0.2,0.3,0.2,0.6c0,0.2,0,0.4,0,0.5
		c0,0.6-0.3,1-1.1,1.1c-0.8,0.2-1.4,0.3-1.9,0.5c-0.5,0.2-0.8,0.4-1.1,0.6c-0.3,0.3-0.6,0.6-0.8,1.1c-0.2,0.4-0.5,1.1-0.8,1.9
		L76.5,91.4c-1.1,2.6-2.7,4.6-4.8,6.1c-2.2,1.5-4.5,2.3-7,2.3c-0.4,0-0.7-0.1-0.9-0.4c-0.2-0.2-0.3-0.5-0.3-0.8
		c0-0.6,0.1-1.2,0.2-1.9c0.2-0.7,0.4-1.3,0.6-1.9c0.3-0.6,0.6-1,0.9-1.4c0.3-0.4,0.7-0.5,1.1-0.5c0.4,0,0.8,0,1.2,0.1
		c0.3,0.1,0.7,0.1,1.2,0.1c1.5,0,2.5-0.3,3.2-0.8c0.7-0.6,1.3-1.5,1.9-2.7L78,80L65.5,52.3c-0.4-0.8-0.7-1.4-1-1.8
		c-0.3-0.5-0.6-0.8-0.9-1.1s-0.7-0.5-1.1-0.6c-0.4-0.1-1-0.3-1.7-0.4c-0.7-0.2-1-0.6-1-1.2c0-0.1,0-0.3,0-0.5c0-0.2,0.1-0.4,0.2-0.6
		c1.1,0.1,2.2,0.2,3.3,0.2c1.1,0,2.4,0,3.8,0h2.3c1.3,0,2.5,0,3.6,0s2.3-0.1,3.4-0.2c0.1,0.2,0.2,0.3,0.2,0.6c0,0.2,0,0.4,0,0.5
		c0,0.6-0.4,1-1.1,1.1c-1.2,0.2-2.1,0.4-2.5,0.7c-0.4,0.2-0.6,0.6-0.6,1c0,0.3,0.2,0.9,0.5,2c0.3,1.1,0.9,2.4,1.6,4L81.7,71.6z"/>
	<path className="st0" d="M134.9,53.5c0.2-0.2,0.7-0.7,1.5-1.5c0.8-0.9,1.7-1.8,3-2.8c1.2-1,2.6-1.9,4.2-2.7c1.6-0.8,3.3-1.2,5.2-1.2
		c3.2,0,5.5,1,6.9,2.9c1.3,1.9,2,4.6,2,8v19.6c0,0.8,0.5,1.4,1.5,1.8c1,0.4,2,0.7,3.2,0.9c0.5,0.1,0.7,0.4,0.7,1
		c0,0.3-0.1,0.8-0.2,1.3c-1.4-0.1-2.8-0.2-4.1-0.3c-1.3-0.1-2.8-0.1-4.4-0.1c-1.4,0-2.9,0-4.3,0.1c-1.5,0.1-2.9,0.2-4.2,0.3
		c-0.1-0.5-0.2-1-0.2-1.3c0-0.5,0.2-0.9,0.7-1c0.5-0.1,1-0.2,1.5-0.3c0.5-0.2,1-0.3,1.4-0.6c0.4-0.2,0.8-0.5,1.1-0.8
		c0.3-0.3,0.4-0.7,0.4-1.1V59.1c0-3.3-0.4-5.6-1.2-6.7c-0.8-1.2-2.1-1.7-4-1.7c-1.4,0-2.7,0.2-4,0.7c-1.3,0.5-2.4,1.1-3.4,2
		c-1,0.9-1.8,2-2.3,3.3c-0.6,1.3-0.9,2.8-0.9,4.4v14.7c0,0.8,0.5,1.4,1.4,1.8c0.9,0.4,1.9,0.7,3.1,0.9c0.5,0.1,0.7,0.4,0.7,1
		c0,0.3-0.1,0.8-0.2,1.3c-1.4-0.1-2.8-0.2-4.1-0.3c-1.3-0.1-2.7-0.1-4.2-0.1c-1.5,0-2.9,0-4.3,0.1c-1.4,0.1-2.8,0.2-4.2,0.3
		c-0.1-0.5-0.2-1-0.2-1.3c0-0.5,0.2-0.9,0.7-1c1-0.2,2-0.4,3-0.8c1-0.4,1.5-1,1.5-1.9V59.1c0-3.3-0.4-5.6-1.2-6.7
		c-0.8-1.2-2.1-1.7-3.9-1.7c-1.4,0-2.7,0.2-4,0.7c-1.3,0.5-2.4,1.1-3.4,2c-1,0.9-1.8,2-2.4,3.3c-0.6,1.3-0.9,2.8-0.9,4.4v14.7
		c0,0.8,0.5,1.4,1.4,1.8c0.9,0.4,2,0.7,3,0.9c0.5,0.1,0.7,0.4,0.7,1c0,0.3-0.1,0.8-0.2,1.3c-1.4-0.1-2.8-0.2-4.1-0.3
		c-1.3-0.1-2.8-0.1-4.3-0.1c-1.6,0-3.1,0-4.5,0.1c-1.4,0.1-2.8,0.2-4.1,0.3c-0.1-0.5-0.2-1-0.2-1.3c0-0.5,0.2-0.9,0.7-1
		c1.2-0.2,2.3-0.5,3.2-0.9c1-0.4,1.5-1,1.5-1.9v-22c0-1.6-0.3-2.8-0.8-3.5c-0.6-0.7-1.8-1.1-3.7-1.3c-0.7-0.1-1-0.5-1-1.1
		c0-0.3,0.1-0.6,0.2-1.1c0.7,0,1.7,0,3-0.1c1.3-0.1,2.3-0.2,3.1-0.3c1.3-0.2,2.4-0.4,3.4-0.7c1-0.2,1.7-0.4,2.1-0.4
		c0.8,0,1.1,0.4,1.1,1.2c0,0.8-0.1,1.7-0.2,2.7c-0.2,1.1-0.2,2.4-0.2,4.2c0.2-0.2,0.6-0.7,1.4-1.5c0.8-0.9,1.7-1.8,2.9-2.7
		c1.2-1,2.6-1.8,4.1-2.6c1.6-0.8,3.3-1.2,5.2-1.2c2.8,0,4.9,0.7,6.2,2.1C133.9,48.9,134.7,50.9,134.9,53.5z"/>
	<path className="st0" d="M171.4,53.4c0-1.5-0.3-2.6-0.8-3.2c-0.5-0.7-1.7-1.1-3.5-1.2c-0.7-0.1-1.1-0.5-1.1-1.1c0-0.3,0.1-0.7,0.2-1.1
		c0.7,0,1.7,0,2.9-0.1c1.2-0.1,2.2-0.2,3-0.4c1.2-0.2,2.2-0.4,3.2-0.6c0.9-0.2,1.6-0.4,2.1-0.4c0.7,0,1,0.4,1,1.2
		c0,0.6,0,1.4-0.1,2.3c-0.1,0.9-0.1,2.2-0.1,3.9c1.6-2.1,3.4-3.9,5.5-5.3c2-1.4,4.5-2.1,7.5-2.1c1.8,0,3.6,0.4,5.3,1.1
		c1.7,0.8,3.2,1.9,4.5,3.4c1.3,1.5,2.3,3.3,3.1,5.5c0.8,2.2,1.2,4.6,1.2,7.4c0,3-0.6,5.7-1.7,8c-1.1,2.3-2.5,4.3-4.2,5.9
		c-1.7,1.6-3.5,2.8-5.5,3.6c-2,0.8-3.8,1.3-5.5,1.3c-1.7,0-3.4-0.2-5.1-0.5c-1.6-0.3-3.4-0.9-5.1-1.7v14.6c0,1.4,0.6,2.4,1.9,2.9
		c1.2,0.5,3.3,0.9,6.1,1.2c0.6,0.1,0.9,0.5,0.9,1c0,0.3-0.1,0.8-0.2,1.3c-1.2-0.1-2.9-0.2-5.1-0.3c-2.2-0.1-4.2-0.1-6.1-0.1
		c-1.4,0-2.9,0-4.4,0.1c-1.6,0.1-3,0.2-4.4,0.3c-0.1-0.5-0.2-1-0.2-1.3c0-0.5,0.2-0.9,0.7-1c1.2-0.3,2.2-0.6,2.9-1
		c0.8-0.4,1.1-1,1.1-1.9V53.4z M178.2,73.9c1.3,1.4,2.8,2.5,4.4,3.4c1.6,0.8,3.5,1.3,5.5,1.3c1.6,0,2.9-0.3,4.1-1.1
		c1.2-0.7,2.2-1.7,3-2.9c0.8-1.2,1.4-2.7,1.8-4.4c0.4-1.7,0.6-3.5,0.6-5.5c0-2-0.2-4-0.7-5.9c-0.5-1.9-1.1-3.5-2-4.9
		c-0.9-1.4-2-2.5-3.2-3.3c-1.3-0.8-2.7-1.2-4.2-1.2c-1.1,0-2.2,0.3-3.2,0.9s-2.1,1.4-3,2.4c-0.9,1-1.6,2.1-2.2,3.3
		c-0.6,1.2-0.8,2.4-0.8,3.7V73.9z"/>
	<path className="st0" d="M222,53.3c0.2-0.1,0.6-0.6,1.4-1.5c0.8-0.9,1.7-1.8,3-2.7c1.2-1,2.6-1.8,4.2-2.6c1.6-0.8,3.3-1.2,5.2-1.2
		c1.3,0,2.5,0.2,3.7,0.6c1.2,0.4,2.2,1,3.1,1.9c0.9,0.9,1.6,2,2.1,3.3c0.5,1.3,0.8,3,0.8,5v19.7c0,0.8,0.5,1.4,1.5,1.8
		c1,0.4,2.1,0.7,3.3,0.9c0.4,0.1,0.6,0.4,0.6,1c0,0.3-0.1,0.8-0.2,1.3c-1.4-0.1-2.8-0.2-4.1-0.3c-1.3-0.1-2.8-0.1-4.4-0.1
		c-1.4,0-2.8,0-4.3,0.1c-1.4,0.1-2.8,0.2-4.3,0.3c-0.1-0.5-0.2-1-0.2-1.3c0-0.5,0.2-0.9,0.7-1c0.5-0.1,1-0.2,1.5-0.3
		c0.5-0.2,1-0.3,1.4-0.6c0.4-0.2,0.8-0.5,1.1-0.8c0.3-0.3,0.4-0.7,0.4-1.1V60c0-1.3-0.1-2.6-0.2-3.7c-0.2-1.1-0.5-2.1-0.9-3
		c-0.5-0.8-1.1-1.5-1.8-1.9c-0.8-0.5-1.8-0.7-3-0.7c-1.3,0-2.7,0.2-3.9,0.7c-1.3,0.5-2.4,1.1-3.4,2c-1,0.9-1.8,2-2.4,3.3
		c-0.6,1.3-0.9,2.8-0.9,4.4v14.7c0,0.8,0.5,1.4,1.4,1.8c0.9,0.4,1.9,0.7,3.1,0.9c0.5,0.1,0.7,0.4,0.7,1c0,0.3-0.1,0.8-0.2,1.3
		c-1.4-0.1-2.8-0.2-4.1-0.3c-1.3-0.1-2.8-0.1-4.3-0.1c-1.6,0-3.1,0-4.5,0.1c-1.4,0.1-2.8,0.2-4.1,0.3c-0.1-0.5-0.2-1-0.2-1.3
		c0-0.5,0.2-0.9,0.6-1c1.2-0.2,2.3-0.5,3.3-0.9c1-0.4,1.5-1,1.5-1.9V32.2c0-1.7-0.3-2.9-1-3.5c-0.6-0.6-1.9-1-3.9-1.2
		c-0.7-0.1-1-0.5-1-1.1c0-0.3,0.1-0.7,0.2-1.1c0.7,0,1.7,0,2.9-0.1c1.2-0.1,2.4-0.2,3.4-0.3c1.3-0.2,2.4-0.4,3.4-0.6
		c1-0.3,1.7-0.4,2.1-0.4c0.5,0,0.8,0.1,1,0.3c0.2,0.2,0.2,0.5,0.2,0.9c0,0.9-0.1,2-0.2,3.2c-0.2,1.2-0.2,2.6-0.2,4.2V53.3z"/>
	<path className="st0" d="M254.6,63.4c0-2.5,0.5-4.8,1.4-7c0.9-2.2,2.2-4.1,3.8-5.7c1.6-1.6,3.5-2.9,5.7-3.8s4.5-1.4,7.1-1.4
		c2.6,0,5.1,0.4,7.3,1.3c2.2,0.9,4.1,2.1,5.7,3.7c1.6,1.6,2.8,3.5,3.7,5.7c0.9,2.2,1.3,4.7,1.3,7.3c0,2.5-0.5,4.9-1.4,7.1
		c-0.9,2.2-2.2,4.1-3.8,5.7c-1.6,1.6-3.5,2.9-5.7,3.8c-2.2,0.9-4.5,1.4-7.1,1.4c-2.6,0-5.1-0.4-7.3-1.3c-2.2-0.9-4.1-2.1-5.7-3.7
		c-1.6-1.6-2.8-3.4-3.7-5.7C255,68.5,254.6,66,254.6,63.4z M262.2,60.7c0,2,0.2,4,0.6,6.1c0.4,2.1,1.1,4,2,5.7
		c0.9,1.7,2,3.1,3.4,4.2c1.4,1.1,3.1,1.7,5.1,1.7c3.1,0,5.5-1.1,7.2-3.2c1.7-2.2,2.5-4.9,2.5-8.3c0-2-0.2-4.1-0.6-6.3
		c-0.4-2.2-1-4.2-1.9-6c-0.9-1.8-2-3.3-3.5-4.5c-1.4-1.2-3.2-1.8-5.3-1.8c-2.9,0-5.2,1-6.9,3S262.2,56.4,262.2,60.7z"/>
	<path className="st0" d="M330.6,75.8c0,0.8,0.5,1.4,1.5,1.8c1,0.4,2,0.7,3.2,0.9c0.5,0.1,0.7,0.4,0.7,1c0,0.3-0.1,0.8-0.2,1.3
		c-1.4-0.1-2.8-0.2-4.1-0.3c-1.3-0.1-2.8-0.1-4.4-0.1c-1.5,0-2.9,0-4.3,0.1c-1.4,0.1-2.8,0.2-4.2,0.3c-0.1-0.5-0.2-1-0.2-1.3
		c0-0.5,0.2-0.9,0.7-1c1.1-0.2,2.2-0.5,3.1-0.9c0.9-0.4,1.4-1,1.4-1.8V60c0-1.3-0.1-2.6-0.2-3.7c-0.2-1.1-0.5-2.1-0.9-3
		c-0.5-0.8-1.1-1.5-1.8-1.9c-0.8-0.5-1.8-0.7-3-0.7c-1.3,0-2.7,0.2-3.9,0.7c-1.3,0.5-2.4,1.1-3.4,2c-1,0.9-1.8,2-2.4,3.3
		s-0.9,2.8-0.9,4.4v14.7c0,0.8,0.5,1.4,1.4,1.8c0.9,0.4,2,0.7,3,0.9c0.5,0.1,0.7,0.4,0.7,1c0,0.3-0.1,0.8-0.2,1.3
		c-1.4-0.1-2.8-0.2-4.1-0.3c-1.3-0.1-2.8-0.1-4.3-0.1c-1.6,0-3.1,0-4.5,0.1c-1.4,0.1-2.8,0.2-4.1,0.3c-0.1-0.5-0.2-1-0.2-1.3
		c0-0.5,0.2-0.9,0.7-1c1.2-0.2,2.3-0.5,3.2-0.9c1-0.4,1.5-1,1.5-1.9v-22c0-1.6-0.3-2.8-0.8-3.5c-0.6-0.7-1.8-1.1-3.7-1.3
		c-0.7-0.1-1-0.5-1-1.1c0-0.3,0.1-0.6,0.2-1.1c0.7,0,1.7,0,3-0.1c1.3-0.1,2.3-0.2,3.1-0.3c1.3-0.2,2.4-0.4,3.4-0.7
		c1-0.2,1.7-0.4,2.1-0.4c0.8,0,1.1,0.4,1.1,1.2c0,0.8-0.1,1.7-0.2,2.7c-0.2,1.1-0.2,2.4-0.2,4.2c2.3-2.6,4.5-4.6,6.6-6
		c2.1-1.4,4.4-2.1,7-2.1c3.3,0,5.7,1,7.4,2.9c1.6,1.9,2.5,4.6,2.5,8V75.8z"/>
	<path className="st0" d="M355.9,71.6c0.8-2,1.7-4.2,2.7-6.3c1-2.2,1.9-4.2,2.7-6.2s1.5-3.7,2.1-5.3c0.5-1.6,0.8-2.7,0.8-3.5
		c0-0.5-0.2-0.8-0.6-1.1c-0.4-0.2-0.9-0.5-1.4-0.6c-0.5-0.2-1-0.4-1.4-0.6c-0.4-0.2-0.6-0.5-0.6-0.9c0-0.1,0-0.3,0.1-0.5
		c0.1-0.2,0.1-0.4,0.2-0.6c2.1,0.2,4.3,0.2,6.7,0.2c1.2,0,2.3,0,3.2,0c0.9,0,1.8-0.1,2.6-0.2c0.1,0.2,0.2,0.3,0.2,0.6
		c0,0.2,0,0.4,0,0.5c0,0.6-0.3,1-1.1,1.1c-0.8,0.2-1.4,0.3-1.9,0.5c-0.5,0.2-0.8,0.4-1.1,0.6c-0.3,0.3-0.6,0.6-0.8,1.1
		c-0.2,0.4-0.5,1.1-0.8,1.9l-16.6,39.1c-1.1,2.6-2.7,4.6-4.8,6.1c-2.2,1.5-4.5,2.3-7,2.3c-0.4,0-0.7-0.1-0.9-0.4
		c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.6,0.1-1.2,0.2-1.9c0.2-0.7,0.4-1.3,0.6-1.9c0.3-0.6,0.6-1,0.9-1.4c0.3-0.4,0.7-0.5,1.1-0.5
		c0.4,0,0.8,0,1.2,0.1c0.3,0.1,0.7,0.1,1.2,0.1c1.5,0,2.5-0.3,3.2-0.8c0.7-0.6,1.3-1.5,1.9-2.7l4.1-9.6l-12.4-27.7
		c-0.4-0.8-0.7-1.4-1-1.8c-0.3-0.5-0.6-0.8-0.9-1.1s-0.7-0.5-1.1-0.6c-0.4-0.1-1-0.3-1.7-0.4c-0.7-0.2-1-0.6-1-1.2
		c0-0.1,0-0.3,0-0.5c0-0.2,0.1-0.4,0.2-0.6c1.1,0.1,2.2,0.2,3.3,0.2s2.4,0,3.8,0h2.3c1.3,0,2.5,0,3.6,0c1.1,0,2.3-0.1,3.4-0.2
		c0.1,0.2,0.2,0.3,0.2,0.6c0,0.2,0,0.4,0,0.5c0,0.6-0.4,1-1.1,1.1c-1.2,0.2-2.1,0.4-2.5,0.7c-0.4,0.2-0.6,0.6-0.6,1
		c0,0.3,0.2,0.9,0.5,2s0.9,2.4,1.6,4L355.9,71.6z"/>
</g>
<path className="st0" d="M57,47c-2.4-5.4-7.6-11-9.4-12.7c-1-0.9-4-3.8-6.9-6.9L45,1.6c1-0.1,2.1,0,3.3,0.2c4.6,0.9,6.5,5.4,5.8,13.4
	h1.4c0.5-4.6,0.1-8.1-1.1-10.4c-1.5-2.6-3.1-4.1-5-4.5C41.1-1.4,34.2,4.6,32,10.7c-2.4,6.8-1.4,13.1,3.2,18.8
	c0.9,1.1,1.9,2.2,2.8,3.3l-2.7,15.9c-1.3-0.8-2.7-1.4-4.5-1.7c-4.6-0.8-9.5,0-14.9,2.9C-3,60.9-0.4,74.5,0.9,78.3
	c2.5,7.8,6.8,12,13.1,13.2c0,0,0.9,0.2,1.2,0.3l0.2,0.1l0.4,0.1C19,92.6,23,92.3,28,91.1c-0.1,0.6-0.2,1.3-0.2,1.9
	c-0.2,1.6-0.4,2.9-0.5,3.9c-0.2,1.4-0.3,2.4-0.4,2.8c-1,4-1.5,8.8-7,10.8c-6.8,2.5-9.6-2.4-9.6-2.4s6.1,1.3,7.8-5.2
	c1.7-6.5-6.6-10.8-11.1-2.4c-4,7.5,2.1,13,7.6,13c4.8,0,12.5-1.5,14.3-14.1c0.3-2,0.7-5.2,1.2-8.9l0-0.2
	c13.6-4.7,22.8-13.5,27.4-26.7C59.7,56,58.8,51.8,57,47z M35.2,20.9c-2.1-4-1.7-9.3-0.9-11c0.6-1.3,3.5-6.6,8.9-8l-4,23.9
	C37.4,23.9,35.9,22.2,35.2,20.9z M14.9,90.3c-1.8-0.4-3.4-1-5-2C5.4,85.3-0.9,71.4,6.1,63c11-15.4,23.3-10.2,27.8-6.3
	c0.4,0.3,0.8,0.7,1.1,1c0.2,0.2,0.3,0.5,0.4,0.7c2.2,3.3,2.7,6.6,1.5,10c-0.3,0.8-1.5,4.3-4.4,6.9c-0.7,0.6-1.4,1.2-2.1,1.7
	c-3.2,2.3-7.6,3.4-10.2,2.4c-2.7-0.9-7-4.8-6.3-10.4c0.8-4.2,7.8-7.8,11.7-6c2.1,1.2,2.9,3.9,2,6.6c-0.7,1.5-1.9,2.9-3.7,4.1
	l0.1,0.5c1.6,0.3,3.2-1.2,5-4.6c1.2-3.3-0.1-7.7-4.5-8.6c-4.4-0.9-10.1,0.9-12.1,7.8c-1.8,6.3,3.5,11.6,8,12.2c2.3,0.3,4,0,7.2-1
	c1-0.4,1.8-1,2.7-1.4l-1.7,9.8C23.6,90.5,19,91.1,14.9,90.3z M51.1,60.1c-0.1,0.9-0.3,1.7-0.4,2.5c-0.1,0.3-0.2,0.7-0.2,1
	c-0.1,0.6-0.2,1.2-0.4,1.7l-0.2,0.5c-2.1,6.9-7.1,13.1-12.4,17.5c-0.9,0.7-1.7,1.4-2.7,2.1c-1.5,0.9-2.9,1.5-4.3,2.2l1.7-10
	c4.6-2.9,7.6-6.5,9.2-10.9c1.8-5,0.6-12.8-4.5-17l2.6-15.2c3.9,4.7,7.4,8.9,8.8,11.2C50.7,51.1,51.7,55.1,51.1,60.1z"/>
<g>
	<path className="st1" d="M203.9,87.2V93h7.8v3h-7.8v7.2h-3V84.3h11.8v2.9H203.9z"/>
	<path className="st1" d="M213.7,96c0-4.3,2.8-7.4,6.6-7.4c3.8,0,6.6,3.1,6.6,7.4s-2.7,7.4-6.6,7.4C216.4,103.4,213.7,100.3,213.7,96z
		 M223.9,96c0-2.8-1.6-4.5-3.7-4.5c-2.1,0-3.7,1.8-3.7,4.5c0,2.8,1.6,4.5,3.7,4.5C222.3,100.5,223.9,98.7,223.9,96z"/>
	<path className="st1" d="M236.3,88.6v3.2c-0.3-0.1-0.5-0.1-0.7-0.1c-2.2,0-3.6,1.5-3.6,4.5v7h-2.9V88.8h2.7v2.1
		C232.8,89.4,234.3,88.6,236.3,88.6z"/>
	<path className="st1" d="M243.5,101.2l1-2.7c1.3,1.2,3.3,2.1,5.3,2.1c2.5,0,3.6-1.1,3.6-2.5c0-4.1-9.6-1.4-9.6-8.4
		c0-3.1,2.1-5.6,6.5-5.6c1.9,0,4,0.6,5.4,1.7l-0.9,2.7c-1.5-1-3-1.5-4.5-1.5c-2.5,0-3.5,1.1-3.5,2.6c0,4,9.6,1.4,9.6,8.3
		c0,3-2.1,5.6-6.5,5.6C247.4,103.5,244.9,102.5,243.5,101.2z"/>
	<path className="st1" d="M270.3,97.1h-9.7c0.3,2.1,1.8,3.5,4,3.5c1.4,0,2.5-0.5,3.3-1.6l1.5,2.1c-1.1,1.5-2.8,2.3-5,2.3
		c-4.1,0-6.8-3.1-6.8-7.4s2.7-7.4,6.4-7.4c3.6,0,6.2,2.9,6.2,7.5C270.4,96.3,270.3,96.7,270.3,97.1z M260.6,94.8h7
		c-0.2-2.1-1.6-3.5-3.5-3.5C262.3,91.3,260.9,92.7,260.6,94.8z"/>
	<path className="st1" d="M284.9,94.9v8.3h-2.9v-7.8c0-2.6-1.1-3.8-2.9-3.8c-2.1,0-3.4,1.5-3.4,4.3v7.3h-2.9V88.8h2.7v1.9
		c0.9-1.3,2.4-2,4.2-2C282.7,88.6,284.9,90.6,284.9,94.9z"/>
	<path className="st1" d="M287.9,84.4c0-1.1,0.8-2,1.8-2c1.1,0,1.8,0.8,1.8,1.9c0,1.2-0.8,2.1-1.8,2.1C288.7,86.4,287.9,85.5,287.9,84.4
		z M288.3,88.8h2.9v14.4h-2.9V88.8z"/>
	<path className="st1" d="M293.6,96c0-4.3,2.8-7.4,6.6-7.4c3.8,0,6.6,3.1,6.6,7.4s-2.7,7.4-6.6,7.4C296.3,103.4,293.6,100.3,293.6,96z
		 M303.8,96c0-2.8-1.6-4.5-3.7-4.5c-2.1,0-3.6,1.8-3.6,4.5c0,2.8,1.6,4.5,3.6,4.5C302.2,100.5,303.8,98.7,303.8,96z"/>
	<path className="st1" d="M316.3,88.6v3.2c-0.3-0.1-0.5-0.1-0.7-0.1c-2.2,0-3.6,1.5-3.6,4.5v7h-2.9V88.8h2.7v2.1
		C312.7,89.4,314.2,88.6,316.3,88.6z"/>
	<path className="st1" d="M317,101.8l1.1-2.6c1.1,0.8,2.7,1.4,4.3,1.4c1.8,0,2.6-0.6,2.6-1.6c0-2.8-7.6-0.2-7.6-5.9
		c0-2.7,2.1-4.5,5.4-4.5c1.6,0,3.5,0.5,4.6,1.2l-1.1,2.6c-1.2-0.8-2.4-1.1-3.5-1.1c-1.8,0-2.6,0.7-2.6,1.6c0,2.9,7.6,0.3,7.6,6
		c0,2.7-2.1,4.5-5.6,4.5C320.2,103.4,318.1,102.7,317,101.8z"/>
</g>
</svg>
</div>
    )
}

export default HeaderLogo;