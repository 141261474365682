import React, { useState } from 'react';
import { Link } from 'gatsby'
import { Alert } from 'react-bootstrap';

import styles from './CoronavirusAlert.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons"

const CoronavirusAlert = () => {
    const [ show, setShow ]= useState(true);

    if (show) {
        return (
            <Alert variant="warning" className={`${styles.alertBox} d-none d-md-block`} onClose={() => setShow(false)} dismissible>
                <p className="text-center" style={{marginBottom: 0, padding: '10px'}}>
                    <Link to="/covid-19-policy"className={styles.alertText}>We are taking precautions with your safety in mind. Please read our COVID-19 policy <FontAwesomeIcon icon={faLongArrowRight} aria-hidden="true" style={{paddingTop: '4px'}} /></Link>
                </p>
            </Alert>
        );
    } else {
        return null
    }
    
}

export default CoronavirusAlert