/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import useSticky from "../hooks/useSticky"
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from "./Header/header"
import Footer from "./Footer/Footer"
import "./layout.css"

const Layout = ({ children }) => {
  const { isSticky, element } = useSticky()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="site">
      <Header siteTitle={data.site.siteMetadata.title}
        sticky={isSticky}
      />
      <div>
        <main className="site-content">
          <div ref={element}>
            {children}
            </div>
        </main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
