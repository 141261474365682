import React from "react";

const HeaderLogo = ({
    style = {},
    fill = '',
    width = '250px',
    height = '76px',
    viewBox = '0 0 250 74.9',
    className = ''
}) => {
    return (
        <div>
        <svg 
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
>
<g>
	<path class="st0" d="M54.7,48c0.5-1.4,1.1-2.8,1.8-4.3c0.7-1.5,1.3-2.8,1.8-4.1c0.6-1.3,1-2.5,1.4-3.5c0.4-1,0.5-1.8,0.5-2.3
		c0-0.3-0.1-0.6-0.4-0.7c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.7-0.3-0.9-0.4c-0.3-0.1-0.4-0.3-0.4-0.6c0-0.1,0-0.2,0.1-0.3
		c0-0.1,0.1-0.3,0.1-0.4c1.4,0.1,2.9,0.2,4.5,0.2c0.8,0,1.6,0,2.2,0c0.6,0,1.2-0.1,1.7-0.1c0.1,0.1,0.1,0.2,0.1,0.4
		c0,0.1,0,0.3,0,0.3c0,0.4-0.2,0.7-0.7,0.8c-0.5,0.1-1,0.2-1.3,0.3c-0.3,0.1-0.6,0.3-0.8,0.4c-0.2,0.2-0.4,0.4-0.5,0.7
		c-0.2,0.3-0.4,0.7-0.6,1.2L51.3,61.3c-0.7,1.7-1.8,3.1-3.3,4.1c-1.4,1-3,1.5-4.7,1.5c-0.3,0-0.5-0.1-0.6-0.2
		c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.4,0.1-0.8,0.2-1.3s0.3-0.9,0.4-1.2c0.2-0.4,0.4-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.7-0.4
		c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.5,0.1,0.8,0.1c1,0,1.7-0.2,2.2-0.6c0.5-0.4,0.9-1,1.2-1.8l2.8-6.4L43.9,35
		c-0.3-0.5-0.5-0.9-0.7-1.2c-0.2-0.3-0.4-0.6-0.6-0.8c-0.2-0.2-0.5-0.3-0.8-0.4c-0.3-0.1-0.7-0.2-1.1-0.2c-0.5-0.1-0.7-0.4-0.7-0.8
		c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.3,0.1-0.4c0.7,0.1,1.5,0.1,2.2,0.1c0.7,0,1.6,0,2.5,0h1.6c0.9,0,1.7,0,2.4,0c0.8,0,1.5-0.1,2.3-0.1
		c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.3,0,0.3c0,0.4-0.2,0.7-0.7,0.7c-0.8,0.1-1.4,0.3-1.7,0.5c-0.3,0.2-0.4,0.4-0.4,0.7
		c0,0.2,0.1,0.6,0.3,1.4c0.2,0.7,0.6,1.6,1.1,2.7L54.7,48z"/>
	<path class="st0" d="M90.4,35.9c0.1-0.1,0.5-0.5,1-1c0.5-0.6,1.2-1.2,2-1.8c0.8-0.7,1.8-1.2,2.8-1.8c1.1-0.5,2.2-0.8,3.5-0.8
		c2.2,0,3.7,0.6,4.6,1.9c0.9,1.3,1.4,3.1,1.4,5.3v13.2c0,0.5,0.3,0.9,1,1.2c0.7,0.3,1.4,0.5,2.2,0.6c0.3,0.1,0.5,0.3,0.5,0.7
		c0,0.2,0,0.5-0.1,0.9c-0.9-0.1-1.9-0.1-2.8-0.2c-0.9-0.1-1.9-0.1-2.9-0.1c-0.9,0-1.9,0-2.9,0.1c-1,0.1-1.9,0.1-2.8,0.2
		c-0.1-0.4-0.1-0.7-0.1-0.9c0-0.4,0.2-0.6,0.5-0.7c0.3,0,0.7-0.1,1-0.2c0.3-0.1,0.7-0.2,0.9-0.4c0.3-0.1,0.5-0.3,0.7-0.5
		c0.2-0.2,0.3-0.4,0.3-0.7V39.6c0-2.2-0.3-3.7-0.8-4.5c-0.5-0.8-1.4-1.2-2.7-1.2c-0.9,0-1.8,0.2-2.7,0.5c-0.8,0.3-1.6,0.8-2.2,1.4
		c-0.7,0.6-1.2,1.3-1.6,2.2c-0.4,0.9-0.6,1.9-0.6,3v9.9c0,0.5,0.3,0.9,0.9,1.2c0.6,0.3,1.3,0.5,2.1,0.6c0.3,0.1,0.5,0.3,0.5,0.7
		c0,0.2,0,0.5-0.1,0.9C93,54.1,92.1,54,91.2,54c-0.9-0.1-1.8-0.1-2.8-0.1c-1,0-1.9,0-2.9,0.1c-1,0.1-1.9,0.1-2.8,0.2
		c-0.1-0.4-0.1-0.7-0.1-0.9c0-0.4,0.2-0.6,0.5-0.7c0.7-0.1,1.3-0.3,2-0.6c0.7-0.3,1-0.7,1-1.3V39.6c0-2.2-0.3-3.7-0.8-4.5
		c-0.5-0.8-1.4-1.2-2.6-1.2c-0.9,0-1.8,0.2-2.7,0.5c-0.8,0.3-1.6,0.8-2.3,1.4c-0.7,0.6-1.2,1.3-1.6,2.2c-0.4,0.9-0.6,1.9-0.6,3v9.9
		c0,0.5,0.3,0.9,0.9,1.2c0.6,0.3,1.3,0.5,2,0.6c0.3,0.1,0.5,0.3,0.5,0.7c0,0.2,0,0.5-0.1,0.9C77.9,54.1,77,54,76.1,54
		c-0.9-0.1-1.9-0.1-2.9-0.1c-1.1,0-2.1,0-3,0.1c-0.9,0.1-1.9,0.1-2.8,0.2c-0.1-0.4-0.1-0.7-0.1-0.9c0-0.4,0.2-0.6,0.5-0.7
		c0.8-0.1,1.5-0.4,2.2-0.6c0.7-0.3,1-0.7,1-1.3V36c0-1.1-0.2-1.9-0.6-2.3c-0.4-0.5-1.2-0.8-2.5-0.9c-0.5-0.1-0.7-0.3-0.7-0.8
		c0-0.2,0-0.4,0.1-0.7c0.5,0,1.1,0,2-0.1c0.8-0.1,1.5-0.1,2.1-0.2c0.8-0.1,1.6-0.3,2.2-0.5c0.7-0.2,1.1-0.2,1.4-0.2
		c0.5,0,0.8,0.3,0.8,0.8c0,0.5-0.1,1.1-0.2,1.8c-0.1,0.7-0.2,1.6-0.2,2.8c0.1-0.1,0.4-0.5,0.9-1c0.5-0.6,1.2-1.2,2-1.8
		s1.7-1.2,2.8-1.8c1-0.5,2.2-0.8,3.5-0.8c1.9,0,3.3,0.5,4.2,1.4C89.7,32.8,90.3,34.1,90.4,35.9z"/>
	<path class="st0" d="M114.9,35.8c0-1-0.2-1.7-0.5-2.2c-0.3-0.4-1.1-0.7-2.4-0.8c-0.5-0.1-0.7-0.3-0.7-0.7c0-0.2,0-0.5,0.1-0.8
		c0.5,0,1.1,0,1.9-0.1c0.8-0.1,1.5-0.1,2-0.2c0.8-0.1,1.5-0.2,2.1-0.4c0.6-0.2,1.1-0.2,1.4-0.2c0.5,0,0.7,0.3,0.7,0.8
		c0,0.4,0,0.9-0.1,1.5c-0.1,0.6-0.1,1.5-0.1,2.6c1.1-1.4,2.3-2.6,3.7-3.5c1.4-1,3-1.4,5-1.4c1.2,0,2.4,0.3,3.5,0.8
		c1.1,0.5,2.1,1.3,3,2.2c0.9,1,1.6,2.2,2.1,3.7c0.5,1.4,0.8,3.1,0.8,4.9c0,2-0.4,3.8-1.1,5.4c-0.8,1.6-1.7,2.9-2.8,3.9
		c-1.1,1.1-2.3,1.9-3.7,2.4c-1.3,0.6-2.5,0.8-3.7,0.8c-1.2,0-2.3-0.1-3.4-0.3c-1.1-0.2-2.2-0.6-3.4-1.1v9.8c0,0.9,0.4,1.6,1.2,1.9
		c0.8,0.3,2.2,0.6,4.1,0.8c0.4,0.1,0.6,0.3,0.6,0.7c0,0.2,0,0.5-0.1,0.9c-0.8-0.1-2-0.1-3.4-0.2c-1.4-0.1-2.8-0.1-4.1-0.1
		c-0.9,0-1.9,0-3,0.1c-1,0.1-2,0.1-3,0.2c-0.1-0.4-0.1-0.7-0.1-0.9c0-0.4,0.2-0.6,0.5-0.7c0.8-0.2,1.4-0.4,2-0.7
		c0.5-0.3,0.8-0.7,0.8-1.2V35.8z M119.4,49.5c0.9,0.9,1.9,1.7,3,2.2c1.1,0.6,2.3,0.8,3.7,0.8c1,0,2-0.2,2.8-0.7
		c0.8-0.5,1.5-1.1,2-1.9c0.5-0.8,0.9-1.8,1.2-2.9c0.3-1.1,0.4-2.4,0.4-3.7c0-1.4-0.2-2.7-0.5-3.9c-0.3-1.2-0.8-2.3-1.4-3.3
		c-0.6-0.9-1.3-1.7-2.2-2.2s-1.8-0.8-2.8-0.8c-0.7,0-1.4,0.2-2.2,0.6s-1.4,0.9-2,1.6c-0.6,0.7-1.1,1.4-1.5,2.2
		c-0.4,0.8-0.6,1.6-0.6,2.5V49.5z"/>
	<path class="st0" d="M148.8,35.7c0.1-0.1,0.4-0.4,0.9-1c0.5-0.6,1.2-1.2,2-1.8c0.8-0.7,1.7-1.2,2.8-1.8c1-0.5,2.2-0.8,3.5-0.8
		c0.9,0,1.7,0.1,2.5,0.4c0.8,0.3,1.5,0.7,2.1,1.2c0.6,0.6,1.1,1.3,1.4,2.2c0.4,0.9,0.5,2,0.5,3.4v13.2c0,0.5,0.3,0.9,1,1.2
		c0.7,0.3,1.4,0.5,2.2,0.6c0.3,0.1,0.4,0.3,0.4,0.7c0,0.2,0,0.5-0.1,0.9c-0.9-0.1-1.9-0.1-2.7-0.2c-0.9-0.1-1.9-0.1-3-0.1
		c-0.9,0-1.9,0-2.9,0.1c-1,0.1-1.9,0.1-2.8,0.2c-0.1-0.4-0.1-0.7-0.1-0.9c0-0.4,0.2-0.6,0.5-0.7c0.3,0,0.7-0.1,1-0.2
		c0.3-0.1,0.7-0.2,0.9-0.4s0.5-0.3,0.7-0.5c0.2-0.2,0.3-0.4,0.3-0.7V40.2c0-0.9-0.1-1.7-0.2-2.5s-0.3-1.4-0.6-2
		c-0.3-0.6-0.7-1-1.2-1.3c-0.5-0.3-1.2-0.5-2-0.5c-0.9,0-1.8,0.2-2.6,0.5c-0.9,0.3-1.6,0.8-2.3,1.4c-0.7,0.6-1.2,1.3-1.6,2.2
		c-0.4,0.9-0.6,1.9-0.6,3v9.9c0,0.5,0.3,0.9,0.9,1.2c0.6,0.3,1.3,0.5,2.1,0.6c0.3,0.1,0.5,0.3,0.5,0.7c0,0.2,0,0.5-0.1,0.9
		c-0.9-0.1-1.9-0.1-2.8-0.2c-0.9-0.1-1.9-0.1-2.9-0.1c-1.1,0-2.1,0-3,0.1c-0.9,0.1-1.9,0.1-2.8,0.2c-0.1-0.4-0.1-0.7-0.1-0.9
		c0-0.4,0.1-0.6,0.4-0.7c0.8-0.1,1.6-0.4,2.2-0.6c0.7-0.3,1-0.7,1-1.3V21.6c0-1.2-0.2-1.9-0.7-2.3c-0.4-0.4-1.3-0.7-2.6-0.8
		c-0.5-0.1-0.7-0.3-0.7-0.8c0-0.2,0-0.5,0.1-0.8c0.5,0,1.1,0,2-0.1c0.8-0.1,1.6-0.1,2.3-0.2c0.8-0.1,1.6-0.3,2.2-0.4
		c0.7-0.2,1.1-0.3,1.4-0.3c0.3,0,0.5,0.1,0.7,0.2c0.1,0.1,0.2,0.3,0.2,0.6c0,0.6-0.1,1.3-0.2,2.1c-0.1,0.8-0.2,1.8-0.2,2.8V35.7z"/>
	<path class="st0" d="M170.6,42.5c0-1.7,0.3-3.2,0.9-4.7s1.5-2.8,2.6-3.8c1.1-1.1,2.4-1.9,3.8-2.6s3-0.9,4.7-0.9
		c1.8,0,3.4,0.3,4.9,0.9c1.5,0.6,2.8,1.4,3.8,2.5c1.1,1,1.9,2.3,2.5,3.8c0.6,1.5,0.9,3.1,0.9,4.9c0,1.7-0.3,3.3-0.9,4.7
		c-0.6,1.5-1.5,2.7-2.6,3.8c-1.1,1.1-2.4,1.9-3.8,2.6c-1.5,0.6-3,0.9-4.7,0.9c-1.8,0-3.4-0.3-4.9-0.9c-1.5-0.6-2.8-1.4-3.8-2.5
		c-1.1-1-1.9-2.3-2.5-3.8C170.9,45.9,170.6,44.3,170.6,42.5z M175.7,40.7c0,1.3,0.1,2.7,0.4,4.1c0.3,1.4,0.7,2.7,1.3,3.8
		c0.6,1.1,1.4,2.1,2.3,2.8c0.9,0.7,2.1,1.1,3.4,1.1c2.1,0,3.7-0.7,4.8-2.2c1.1-1.4,1.7-3.3,1.7-5.6c0-1.3-0.1-2.7-0.4-4.2
		c-0.3-1.5-0.7-2.8-1.3-4c-0.6-1.2-1.4-2.2-2.3-3c-1-0.8-2.1-1.2-3.5-1.2c-1.9,0-3.5,0.7-4.6,2C176.3,35.7,175.7,37.8,175.7,40.7z"
		/>
	<path class="st0" d="M221.6,50.8c0,0.5,0.3,0.9,1,1.2c0.7,0.3,1.4,0.5,2.2,0.6c0.3,0.1,0.5,0.3,0.5,0.7c0,0.2,0,0.5-0.1,0.9
		c-0.9-0.1-1.9-0.1-2.8-0.2c-0.9-0.1-1.9-0.1-2.9-0.1c-1,0-1.9,0-2.9,0.1c-1,0.1-1.9,0.1-2.8,0.2c-0.1-0.4-0.1-0.7-0.1-0.9
		c0-0.4,0.2-0.6,0.5-0.7c0.8-0.1,1.4-0.4,2.1-0.6c0.6-0.3,0.9-0.7,0.9-1.2V40.2c0-0.9-0.1-1.7-0.2-2.5s-0.3-1.4-0.6-2
		c-0.3-0.6-0.7-1-1.2-1.3c-0.5-0.3-1.2-0.5-2-0.5c-0.9,0-1.8,0.2-2.6,0.5c-0.9,0.3-1.6,0.8-2.3,1.4c-0.7,0.6-1.2,1.3-1.6,2.2
		c-0.4,0.9-0.6,1.9-0.6,3v9.9c0,0.5,0.3,0.9,0.9,1.2c0.6,0.3,1.3,0.5,2,0.6c0.3,0.1,0.5,0.3,0.5,0.7c0,0.2,0,0.5-0.1,0.9
		c-0.9-0.1-1.9-0.1-2.8-0.2c-0.9-0.1-1.9-0.1-2.9-0.1c-1.1,0-2.1,0-3,0.1c-0.9,0.1-1.9,0.1-2.8,0.2c-0.1-0.4-0.1-0.7-0.1-0.9
		c0-0.4,0.2-0.6,0.5-0.7c0.8-0.1,1.5-0.4,2.2-0.6c0.7-0.3,1-0.7,1-1.3V36c0-1.1-0.2-1.9-0.6-2.3c-0.4-0.5-1.2-0.8-2.5-0.9
		c-0.5-0.1-0.7-0.3-0.7-0.8c0-0.2,0-0.4,0.1-0.7c0.5,0,1.1,0,2-0.1c0.8-0.1,1.5-0.1,2.1-0.2c0.8-0.1,1.6-0.3,2.2-0.5
		c0.7-0.2,1.1-0.2,1.4-0.2c0.5,0,0.8,0.3,0.8,0.8c0,0.5-0.1,1.1-0.2,1.8c-0.1,0.7-0.2,1.6-0.2,2.8c1.5-1.7,3-3.1,4.5-4
		c1.4-0.9,3-1.4,4.7-1.4c2.2,0,3.8,0.6,4.9,1.9c1.1,1.3,1.6,3.1,1.6,5.3V50.8z"/>
	<path class="st0" d="M238.5,48c0.5-1.4,1.1-2.8,1.8-4.3c0.7-1.5,1.3-2.8,1.8-4.1c0.6-1.3,1-2.5,1.4-3.5c0.4-1,0.5-1.8,0.5-2.3
		c0-0.3-0.1-0.6-0.4-0.7c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.7-0.3-0.9-0.4c-0.3-0.1-0.4-0.3-0.4-0.6c0-0.1,0-0.2,0.1-0.3
		c0-0.1,0.1-0.3,0.1-0.4c1.4,0.1,2.9,0.2,4.5,0.2c0.8,0,1.6,0,2.2,0c0.6,0,1.2-0.1,1.7-0.1c0.1,0.1,0.1,0.2,0.1,0.4
		c0,0.1,0,0.3,0,0.3c0,0.4-0.2,0.7-0.7,0.8c-0.5,0.1-1,0.2-1.3,0.3s-0.6,0.3-0.8,0.4c-0.2,0.2-0.4,0.4-0.5,0.7
		c-0.2,0.3-0.4,0.7-0.6,1.2L235,61.3c-0.7,1.7-1.8,3.1-3.3,4.1c-1.4,1-3,1.5-4.7,1.5c-0.3,0-0.5-0.1-0.6-0.2
		c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.4,0.1-0.8,0.2-1.3s0.3-0.9,0.4-1.2c0.2-0.4,0.4-0.7,0.6-0.9c0.2-0.2,0.5-0.4,0.7-0.4
		c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.5,0.1,0.8,0.1c1,0,1.7-0.2,2.2-0.6c0.5-0.4,0.9-1,1.2-1.8l2.8-6.4L227.7,35
		c-0.3-0.5-0.5-0.9-0.7-1.2c-0.2-0.3-0.4-0.6-0.6-0.8c-0.2-0.2-0.5-0.3-0.8-0.4c-0.3-0.1-0.7-0.2-1.1-0.2c-0.5-0.1-0.7-0.4-0.7-0.8
		c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.3,0.1-0.4c0.7,0.1,1.5,0.1,2.2,0.1c0.7,0,1.6,0,2.5,0h1.6c0.9,0,1.7,0,2.4,0c0.8,0,1.5-0.1,2.3-0.1
		c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.3,0,0.3c0,0.4-0.2,0.7-0.7,0.7c-0.8,0.1-1.4,0.3-1.7,0.5c-0.3,0.2-0.4,0.4-0.4,0.7
		c0,0.2,0.1,0.6,0.3,1.4c0.2,0.7,0.6,1.6,1.1,2.7L238.5,48z"/>
</g>
<path class="st0" d="M38.2,31.5c-1.6-3.6-5.1-7.4-6.3-8.5c-0.6-0.6-2.7-2.6-4.6-4.6l2.9-17.3c0.7-0.1,1.4,0,2.2,0.1
	c3.1,0.6,4.3,3.6,3.9,9h0.9c0.3-3.1,0.1-5.4-0.7-7c-1-1.7-2.1-2.7-3.3-3c-5.5-1.1-10.2,2.9-11.6,7c-1.6,4.6-0.9,8.8,2.1,12.6
	c0.6,0.7,1.3,1.5,1.9,2.2l-1.8,10.7c-0.9-0.5-1.8-1-3-1.2c-3.1-0.5-6.4,0-10,1.9C-2,40.8-0.2,49.9,0.6,52.5c1.7,5.2,4.6,8,8.8,8.9
	c0,0,0.6,0.1,0.8,0.2l0.2,0l0.3,0.1c2.1,0.4,4.8,0.2,8.1-0.6c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,1.1-0.2,1.9-0.3,2.6
	c-0.1,0.9-0.2,1.6-0.3,1.9c-0.7,2.6-1,5.9-4.7,7.3c-4.5,1.7-6.4-1.6-6.4-1.6s4.1,0.9,5.3-3.5c1.2-4.4-4.4-7.2-7.4-1.6
	c-2.7,5,1.4,8.7,5.1,8.7c3.2,0,8.4-1,9.6-9.4c0.2-1.4,0.5-3.5,0.8-6l0-0.1c9.1-3.1,15.3-9.1,18.4-17.9C40,37.5,39.4,34.7,38.2,31.5z
	 M23.6,14c-1.4-2.7-1.2-6.2-0.6-7.4c0.4-0.9,2.3-4.4,6-5.4l-2.7,16C25.1,16,24.1,14.9,23.6,14z M10,60.5c-1.2-0.2-2.3-0.7-3.3-1.3
	c-3-2-7.2-11.3-2.6-17c7.4-10.3,15.6-6.8,18.7-4.2c0.3,0.2,0.5,0.5,0.7,0.7c0.1,0.2,0.2,0.3,0.3,0.5c1.4,2.2,1.8,4.4,1,6.7
	c-0.2,0.5-1,2.9-2.9,4.6c-0.5,0.4-0.9,0.8-1.4,1.2c-2.2,1.6-5.1,2.2-6.8,1.6s-4.7-3.2-4.3-7c0.5-2.8,5.2-5.2,7.8-4
	c1.4,0.8,2,2.6,1.3,4.4c-0.4,1-1.3,1.9-2.5,2.8l0.1,0.3c1.1,0.2,2.2-0.8,3.4-3.1c0.8-2.2-0.1-5.1-3-5.7c-2.9-0.6-6.8,0.6-8.1,5.2
	c-1.2,4.2,2.3,7.8,5.3,8.2c1.6,0.2,2.7,0,4.8-0.7c0.7-0.3,1.2-0.6,1.8-1l-1.1,6.6C15.8,60.6,12.7,61.1,10,60.5z M34.3,40.3
	c0,0.6-0.2,1.1-0.3,1.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.4-0.1,0.8-0.3,1.2l-0.1,0.3c-1.4,4.6-4.8,8.8-8.3,11.7c-0.6,0.5-1.2,1-1.8,1.4
	c-1,0.6-1.9,1-2.9,1.4l1.1-6.7c3.1-2,5.1-4.4,6.1-7.3c1.2-3.4,0.4-8.6-3-11.4l1.7-10.2c2.6,3.1,5,6,5.9,7.5
	C34,34.2,34.7,36.9,34.3,40.3z"/>
<g>
	<path class="st1" d="M136.7,58.5v3.9h5.2v2h-5.2v4.9h-2V56.5h7.9v2H136.7z"/>
	<path class="st1" d="M143.2,64.3c0-2.9,1.9-4.9,4.4-4.9c2.6,0,4.4,2,4.4,4.9c0,2.9-1.8,5-4.4,5C145.1,69.3,143.2,67.2,143.2,64.3z
		 M150.1,64.3c0-1.8-1.1-3-2.5-3c-1.4,0-2.4,1.2-2.4,3c0,1.8,1.1,3,2.4,3C149,67.4,150.1,66.2,150.1,64.3z"/>
	<path class="st1" d="M158.4,59.4v2.2c-0.2,0-0.3-0.1-0.4-0.1c-1.5,0-2.4,1-2.4,3v4.7h-1.9v-9.7h1.8v1.4
		C156,59.9,157,59.4,158.4,59.4z"/>
	<path class="st1" d="M163.2,67.8l0.7-1.8c0.9,0.8,2.2,1.4,3.6,1.4c1.7,0,2.4-0.7,2.4-1.6c0-2.7-6.4-0.9-6.4-5.7
		c0-2,1.4-3.8,4.4-3.8c1.3,0,2.7,0.4,3.6,1.1l-0.6,1.8c-1-0.7-2-1-3-1c-1.7,0-2.4,0.8-2.4,1.7c0,2.7,6.4,0.9,6.4,5.6
		c0,2-1.4,3.8-4.4,3.8C165.8,69.3,164.1,68.7,163.2,67.8z"/>
	<path class="st1" d="M181.2,65.1h-6.5c0.2,1.4,1.2,2.3,2.7,2.3c0.9,0,1.7-0.3,2.2-1l1,1.4c-0.7,1-1.9,1.6-3.3,1.6
		c-2.8,0-4.6-2.1-4.6-5c0-2.9,1.8-4.9,4.3-4.9c2.4,0,4.2,2,4.2,5C181.2,64.6,181.2,64.8,181.2,65.1z M174.7,63.6h4.7
		c-0.2-1.4-1.1-2.4-2.3-2.4C175.8,61.2,174.9,62.1,174.7,63.6z"/>
	<path class="st1" d="M191,63.6v5.5H189v-5.3c0-1.7-0.7-2.6-2-2.6c-1.4,0-2.3,1-2.3,2.9v4.9h-1.9v-9.7h1.8v1.2
		c0.6-0.9,1.6-1.4,2.8-1.4C189.5,59.4,191,60.7,191,63.6z"/>
	<path class="st1" d="M192.9,56.5c0-0.8,0.5-1.4,1.2-1.4c0.7,0,1.2,0.6,1.2,1.3c0,0.8-0.5,1.4-1.2,1.4
		C193.5,57.9,192.9,57.3,192.9,56.5z M193.2,59.5h1.9v9.7h-1.9V59.5z"/>
	<path class="st1" d="M196.8,64.3c0-2.9,1.9-4.9,4.4-4.9c2.6,0,4.4,2,4.4,4.9c0,2.9-1.8,5-4.4,5C198.6,69.3,196.8,67.2,196.8,64.3z
		 M203.6,64.3c0-1.8-1.1-3-2.5-3c-1.4,0-2.4,1.2-2.4,3c0,1.8,1.1,3,2.4,3C202.6,67.4,203.6,66.2,203.6,64.3z"/>
	<path class="st1" d="M212,59.4v2.2c-0.2,0-0.3-0.1-0.4-0.1c-1.5,0-2.4,1-2.4,3v4.7h-1.9v-9.7h1.8v1.4
		C209.6,59.9,210.6,59.4,212,59.4z"/>
	<path class="st1" d="M212.5,68.2l0.7-1.7c0.7,0.6,1.8,1,2.9,1c1.2,0,1.7-0.4,1.7-1.1c0-1.8-5.1-0.1-5.1-4c0-1.8,1.4-3,3.6-3
		c1.1,0,2.4,0.3,3.1,0.8l-0.7,1.7c-0.8-0.5-1.6-0.7-2.4-0.7c-1.2,0-1.7,0.5-1.7,1.1c0,2,5.1,0.2,5.1,4c0,1.8-1.4,3-3.7,3
		C214.6,69.3,213.2,68.8,212.5,68.2z"/>
</g>
</svg>
</div>
    )
}

export default HeaderLogo;