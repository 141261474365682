import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col, Button } from 'react-bootstrap'

import styles from './Footer.module.css'
import HeaderLogoMobile from '../../components/Header/HeaderLogoMobile'

import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagramSquare,
  faFacebookSquare,
  faTwitterSquare,
  faYoutubeSquare,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
    return (
        <>
        <footer className="mt-auto">
            <Container>
                <Row className="py-4 h-100">
                    <Col sm={4} className="my-auto">
                    <HeaderLogoMobile />
                    <hr style={{
                        borderBottom: '1px solid rgba(255, 255, 255, 0.534)'
                    }}/>
                    <div>
                        <p className={styles.footerDesc}>We are a revolutionary care-coordination service and communication platform guiding seniors, their families, and professional advisors in managing an individual’s needs at home.</p>
                    </div>
                    </Col>
                    <Col className="my-auto" sm={2}>
                        <h4>Helpful Links</h4>
                        <ul className={styles.footerMenu}>
                            <li>
                                <Link to="/privacy-policy" className={styles.footerLink}>Policies &amp; HIPAA</Link>
                            </li>
                            <li>
                                <Link to="/covid-19-policy" className={styles.footerLink}>COVID-19 Policy</Link>
                            </li>
                            <li>
                                <Link to="/blog" className={styles.footerLink}>Newsfeed</Link>
                            </li>
                            <li>&nbsp;</li>
                        </ul>
                    </Col>
                    <Col className="my-auto" sm={3}>
                        <h4>Menu</h4>
                        <ul className={styles.footerMenu}>
                            <li>
                                <Link to="/" className={styles.footerLink}>Overview</Link>
                            </li>
                            <li>
                                <Link to="/complete-care-approach" className={styles.footerLink}>Complete Care Approach</Link>
                            </li>
                            <li>
                                <Link to="/our-team" className={styles.footerLink}>Our Team</Link>
                            </li>
                            <li>
                                <Link to="/contact" className={styles.footerLink}>Contact</Link>
                            </li>
                        </ul>
                    </Col>
                    <Col className="my-auto" sm={3}>
                        <Row className='mb-4 text-center'>
                            <Col>
                            <a href="https://symphonyforseniors.caretree.me/" target="_blank" rel="noopener noreferrer" ><Button variant="primary" className={styles.loginButton} size="md">Login</Button></a>        
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col>
                            <p style={{
                fontSize: '19px', 
                textTransform: 'uppercase'
              }}><strong>Find Us On </strong>
              <a
              href="https://www.facebook.com/SymphonyForSeniors/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Symphony For Seniors on Facebook"
              title="Symphony For Seniors on Facebook"
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                className={styles.footerSocialIcon}
                aria-hidden="true"
              />
            </a>
            <a
              href="https://twitter.com/SymphonySeniors"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Symphony For Seniors on Instagram"
              title="Symphony For Seniors on Instagram"
            >
              <FontAwesomeIcon
                icon={faTwitterSquare}
                className={styles.footerSocialIcon}
                aria-hidden="true"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/symphony-for-seniors-6a89311b6/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Symphony For Seniors on LinkedIn"
              title="Symphony For Seniors on LinkedIn"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                className={styles.footerSocialIcon}
                aria-hidden="true"
              />
            </a>
            <a
              href="https://www.instagram.com/symphonyforseniors/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Symphony For Seniors on Instagram"
              title="Symphony For Seniors on Instagram"
            >
              <FontAwesomeIcon
                icon={faInstagramSquare}
                className={styles.footerSocialIcon}
                aria-hidden="true"
              />
            </a>

            <a
              href="https://www.youtube.com/channel/UC0OnqBEcEJG491Sw8B0ER7A?reload=9&view_as=subscriber"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Symphony For Seniors on YouTube"
              title="Symphony For Seniors on YouTube"
            >
              <FontAwesomeIcon
                icon={faYoutubeSquare}
                className={styles.footerSocialIcon}
                aria-hidden="true"
              />
            </a>
            
            </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center"><p><strong>Servicing Arizona</strong></p></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container fluid
             className={styles.subFooter}
             >
            <Container>
                <Row>

                </Row>
                <Row>
                    <Col>
                    <p className={styles.footerText}>© Symphony {new Date().getFullYear()}  |  <Link to="/website-privacy-policy">Privacy Policy</Link></p>
                    </Col>
                </Row>
                </Container>
             </Container>
        </footer>
        </>
    )
}

export default Footer