import React from "react"
import { Link } from "gatsby"
import {
  Container,
  Navbar,
  Nav,
  Button,
} from "react-bootstrap"
import HeaderLogo from './HeaderLogo'
import HeaderLogoMobile from './HeaderLogoMobile'
import styles from "./Header.module.css"

const NavBar = () => (
  <Container
    fluid
    style={{
      padding: 0,
    }}
  >
    <Navbar
      collapseOnSelect
      expand="lg"
      className={styles.navBarBackground}
      variant="dark"
      //fixed="top"
    >
        <Link to="/">
      <Navbar.Brand className="brand">
        <HeaderLogo
          className="d-none d-md-block headerLogo"
         />
        <HeaderLogoMobile className="d-md-none"/>
      </Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav justify-content-center">
        <Nav
          className="mr-auto justify-content-center"
          style={{
            width: "100%",
          }}
          
        >
          <Link className={`navbar-link ${styles.navBarItem}`} to="/" activeClassName="active">
            <Nav.Link as="span">
              Overview
            </Nav.Link>
          </Link>
          <Link className={`navbar-link ${styles.navBarItem}`} to="/complete-care-approach/" activeClassName="active">
            <Nav.Link as="span">
              Complete Care Approach
            </Nav.Link>
          </Link>
          <Link className={`navbar-link ${styles.navBarItem}`} to="/our-team/" activeClassName="active">
            <Nav.Link as="span">
              Our Team
            </Nav.Link>
          </Link>
          <Link className={`navbar-link ${styles.navBarItem}`} to="/contact/" activeClassName="active">
            <Nav.Link as="span">
              Contact
            </Nav.Link>
          </Link>
          
        </Nav>
        <Nav>
            {/* <HeaderModalButton buttonText="Login"/> */}
            <a href="https://symphonyforseniors.caretree.me/" target="_blank" rel="noopener noreferrer" ><Button variant="primary" className={styles.loginButton} size="md">Login</Button></a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Container>
)

export default NavBar
