import React from "react"

import HeaderTopBar from "./HeaderTopBar"
import NavBar from "./NavBar"
import CoronaVirusAlert from "../CoronavirusAlert/CoronavirusAlert"

const Header = ({ sticky }) => (
  <header className={sticky ? "navbar-sticky" : ""}>
    <HeaderTopBar />
    <NavBar />
    <CoronaVirusAlert />
  </header>
)

export default Header
