import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import styles from './Header.module.css'

import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneAlt
} from "@fortawesome/free-solid-svg-icons"


const HeaderTopBar = () => (
    <div className={styles.topBar}>
        <Container fluid>
            <Row>
                <Col>
                    <p className="text-center">
                    <FontAwesomeIcon
                icon={faPhoneAlt}
                className={styles.headerPhoneIcon}
                aria-hidden="true"
              /> <a href="tel:480-645-0372">(480) 645-0372</a></p>
                </Col>
            </Row>
        </Container>
    </div>
)

export default HeaderTopBar